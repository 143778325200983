import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AxiosRequest } from '../utils/AxiosRequest'
import { IMAGE } from './Theme'
import ApiConnection from '../utils/ApiConnection'
import { toast } from 'react-toastify'

const Footer = ({data}) => {
    const [email, setemail] = useState("")

    
    const SubmitHandle = async ()=>{
        

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
             toast.error('Email should be proper!');
        } else {

            var FormData = require('form-data');
            var data = new FormData();
            data.append("email", email);

            try {
                const  response = await ApiConnection.post(`subscriber-request`, data)
             
                if(response?.data?.success){
                   
           
                  toast.success(response?.data?.message)
               
                }  else {
                    toast.error(response?.data?.message)
                  
                  }
            } catch(err){
           
                toast.error(err?.response?.data?.message) 
        }

       
        
           
    }

        
    }

  return (
    <div className='footer'>
        <div className='footer-top'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-7'>
                        <h3>{data?.contact_us_header}</h3>
                        <p>{data?.contact_us_label}</p>
                    </div>
                    <div className='col-lg-5'>
                       <input className="form-control" type='text' placeholder='Email Address'
                         value={email}
                         onChange={(e)=>setemail(e.target.value)}
                       />
                       <button className='nsubmitBtn' onClick={SubmitHandle}>
                            <img src={IMAGE.paper} />
                       </button>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-bottom'>
             <div className='container'>
                <ul>
                    <li>
                        <NavLink to="/privacy-policy">Privacy Policy </NavLink>    |   <NavLink to="/terms-condition">    Terms and Conditions</NavLink>
                            <NavLink to="/contact-us"  className={({ isActive }) => (isActive ? "active" : undefined)}> | Contact Us</NavLink>
                      
                    </li>
                    <li>
                        <NavLink>{data?.copyright_statement}</NavLink>
                    </li>
                    <li>
                        <a target='_blank' href='https://itiffyconsultants.com'>{data?.designed_by}</a>
                    </li>
                </ul>
             </div>
        </div>
    </div>
  )
}

export default Footer