import React,{useEffect} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection';
const About = () => {

  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const { loading, Getcms, cmsdata, Getadvertisement, advertisement} = AxiosRequest()
  const dispatch = useDispatch()
  var user = JSON.parse(localStorage.getItem("user"))


  useEffect(()=>{
    dispatch(sidebarAction(!sidebarShow)) 
    Getcms('welcome')
    Getadvertisement('homepage')
  },[])


  const ClickHandle = async (link, id) =>{

    if(link !== ''){
      window.open(link)
    }

    var FormData = require('form-data');
    var data = new FormData();
    data.append("advertisement_id", id);
    data.append("page", 'homepage');
    data.append("has_click", 1);
    data.append("device", 'web');
    data.append("user_id", user?.id == null ? '': user?.id);
    
    try {
        const  response = await ApiConnection.post(`add-management`, data)
       
        if(response?.data?.success){
           
   
       
        }  else {
          
          
          }
    } catch(err){
  
      
    
  }
}


  
  return (
    <div className='about-sec'>
        {loading && <Loader/>}
    <div className='container-fluid'>
        <h2 className='headingStyle'>{cmsdata?.page_name}</h2>
        <div className='row'>
        <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Left'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
        <div className='col-lg-5'>
        <div dangerouslySetInnerHTML={{ __html: cmsdata?.description }} />
                  
            </div>
            <div className='col-lg-3'>
            <img src={BaseUrl + cmsdata?.image} className='w-100' alt="about" />
            </div>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Right'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
          <div className='col-lg-12 mt-5'>
              {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
        </div>
    </div>
</div>
  )
}

export default About