import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidebarshow:false,
    location:'',
    Notification:[]
  };

  export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        sidebarAction: (state) => {
        state.isSidebarshow = !state.isSidebarshow;
    },
      getlocationAction: (state, action) => {
        console.log("reducer", action.payload)
        state.location = action.payload;
    },
    NotificationAction: (state, action) => {
      state.Notification = action.payload;
  },
}
  })

  export const { sidebarAction, getlocationAction, NotificationAction } = sidebarSlice.actions

  export default sidebarSlice.reducer;