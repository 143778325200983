import React, { useEffect, useState } from 'react'
import Loader from '../../utils/Loader'
import ApiConnection from '../../utils/ApiConnection';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationAction } from '../../redux/SidebarSlice';

const Notification = () => {

    const [search, setsearch] = useState("")
    const [Notification, setNotification] = useState([]);
    const [loading, setloading] = useState(false)
    const sidebarShow = useSelector((state) => state.SidebarSlice)
    const dispatch = useDispatch()
    const ReadHandle = async (id)=>{
      setloading(true)
      try {

          const  response = await ApiConnection.put(`user-notification/${id}`)
          if(response?.status == 200){
            toast(response?.data?.message)
            GetNotification()
              setloading(false)
          } else{
            
          }  
      } catch(err){
          
      }

    }

    const GetNotification = async ()=>{
 
      try {
          const  response = await ApiConnection.get('user-notification')
          if(response?.status == 200){
           
              dispatch(NotificationAction(response?.data?.data))
          } else{
            
          }  
      } catch(err){
          
      }
  }



  return (
    <div className='faq-sec'>
      {loading && <Loader/>}
    <div className='container'>
       <h2 className='headingStyle'>Notification
       </h2>
     
       {sidebarShow?.Notification?.length > 0 ?
      
      <div className="noti-sec">
        <ul>
          {sidebarShow?.Notification.map((notification, i) =>{
            return (
              <li style={{position:'relative'}} key={i}>
                <h5>{notification?.name}</h5>
                <h6>{notification?.subject}</h6>
                <p>{notification?.content}</p>
                <span>{moment(notification?.updated_at).format('DD-MM-YYYY')}</span>
                <button onClick={()=>ReadHandle(notification.id)} className='btn btn-primary' style={{position:'absolute', right:'15px',bottom:'15px'}}>Mark as read</button>
                </li>
            )
          })}
           
        </ul>
       </div>
    :
    <h5>No notification list</h5>
    
    }


   
   </div>
</div>
  )
}

export default Notification