var url = window.location.origin + "/images/"

export const IMAGE = {
    logo:url + 'logo.png',
    phone_call:url + 'phone-call.png',
    globe_light:url + 'globe_light.png',
    banner_img:url + 'banner_img.png',
    ride_one:url + 'ride_one.png',
    ride_two:url + 'ride_two.png',
    ride_three:url + 'ride_three.png',
    calender_icon:url + 'calender_icon.png',
    clock_icon:url + 'clock_icon.png',
    loc_icon:url + 'loc_icon.png',
    seden:url + 'seden.png',
    delux:url + 'delux.png',
    custom:url + 'custom.png',
    plus_icon:url + 'plus_icon.png',
    cross_icon:url + 'cross_icon.png',
    pick_icon:url + 'pick_icon.png',
    location_fill:url + 'location-fill.png',
    home:url + 'home.png',
    user:url + 'user.png',
    menu:url + 'menu.png',
    ride:url + 'ride.png',
    paper:url + 'paper-plane.png',
    bell:url + 'bell.png',
}