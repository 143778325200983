import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import ApiConnection from '../../utils/ApiConnection';
import { toast } from 'react-toastify';
import Loader from '../../utils/Loader';

const ContactUs = () => {
    const [email, setemail] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [phone, setphone] = useState("")
    const [subject, setsubject] = useState("")
    const [message, setmessage] = useState("")
    const [loading, setloading] = useState(false)


    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 
    },[])


    const SubmitHandle = async ()=>{
        

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if(fname == ""){
            toast.error('First name should be must');
        } else if(lname == ""){
            toast.error('Last name should be must');
        } else if(phone == ""){
            toast.error('Phone should be must');
        } else if (reg.test(email) === false) {
             toast.error('Email should be proper!');
        } else if(message == ""){
            toast.error('Message should be must');
        } else {

            setloading(true)
            var FormData = require('form-data');
            var data = new FormData();
            data.append("first_name", fname);
            data.append("last_name", lname);
            data.append("email", email);
            data.append("phone",phone);
            data.append("message", message);
            data.append("subject", subject);
            try {
                const  response = await ApiConnection.post(`contact-us`, data)
                setloading(false)
                if(response?.data?.success){
                   
           
                  toast.success(response?.data?.message)
               
                }  else {
                    toast.error(response?.data?.message)
                  
                  }
            } catch(err){
                setloading(false)
                if(err.response?.status === 401){
                toast.error(err.response?.data?.message);   
            } else if(err.response?.status === 422){
              toast.error(err.response?.data?.message);   
           } else if(err.response?.status === 500){
            toast.error(err.response?.data?.message);   
         }
       
        }

       
        
           
    }

        
    }

  return (
    <div className='about-sec'>
             {loading && <Loader/>}
    <div className='container'>
        <h2 className='headingStyle'>Contact Us</h2>
        <div className='row'>
        
        <div className='col-lg-6'>
            <div className='form-group'>
                <label>First Name</label>
                <input type="text" className="form-control" placeholder="First Name"
                value={fname}
                onChange={(e)=>setfname(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Last Name</label>
                <input type="text" className="form-control" placeholder="Last Name"
                value={lname}
                onChange={(e)=>setlname(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Email"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Phone Number</label>
                <input type="email" className="form-control" placeholder="Phone Number"
                value={phone}
                onChange={(e)=>setphone(e.target.value)}
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength="10"
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Subject</label>
                <input type="text" className="form-control" placeholder="Subject"
                value={subject}
                onChange={(e)=>setsubject(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-12'>
            <div className='form-group'>
                <label>Message</label>
               <textarea className='form-control' placeholder='Message'
                value={message}
                onChange={(e)=>setmessage(e.target.value)}
               ></textarea>
            </div>
            </div>
     <div className='col-lg-12 mt-5'>
     <div className='form-group text-right'>
                <button type='button' className='submitBtn' onClick={SubmitHandle}>Submit</button>
            </div>
            </div>
      
    </div>
    </div>
</div>
  )
}

export default ContactUs