import React,{useEffect} from 'react'
import Banner from './Banner'
import AboutHome from './AboutHome'
import Weare from './Weare'
import Faq from './Faq'
import Testimonial from './Testimonial'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest'
import Loader from '../../utils/Loader'
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection'

const Index = () => {
  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const dispatch = useDispatch()
  const { loading, GetHomepagecontent, homedata, Getcms, cmsdata, sitedata, Getsite, Getadvertisement, advertisement} = AxiosRequest()

  useEffect(()=>{
    GetHomepagecontent()
    Getcms('welcome')
    Getadvertisement('welcome-page')
    Getsite()
  },[])

  var user = JSON.parse(localStorage.getItem("user"))
  const ClickHandle = async (link, id) =>{

    if(link !== ''){
      window.open(link)
    }

    var FormData = require('form-data');
    var data = new FormData();
    data.append("advertisement_id", id);
    data.append("page", 'welcome-page');
    data.append("has_click", 1);
    data.append("device", 'web');
    data.append("user_id", user?.id == null ? '': user?.id);
    try {
        const  response = await ApiConnection.post(`add-management`, data)
       
        if(response?.data?.success){
           
   
       
        }  else {
          
          
          }
    } catch(err){
  
      
    
  }
}

  return (
    <>
      {loading && <Loader/>}
    <Banner data={homedata?.banners}  />
    <AboutHome data={cmsdata} sitedata={sitedata} />
    <Weare data={homedata?.testimonial_1} sitedata={sitedata} />
    <Faq data={homedata?.faq} sitedata={sitedata} />
    <Testimonial  data={homedata?.testimonial_2} sitedata={sitedata} />
    <div className='container mb-3'>
    {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
    </div>
    </>
  )
}

export default Index