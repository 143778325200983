import React, { useState } from 'react'
import ApiConnection from './ApiConnection'
import { toast } from 'react-toastify'

export const AxiosRequest = () => {

   
    const [loading, setloading] = useState(false)
    const [faqList, setfaqList] = useState([])
    const [testimonialList, settestimonialList] = useState([])
    const [videoList, setvideoList] = useState([])
    const [homedata, sethomedata] = useState('')
    const [sitedata, setsitedata] = useState('')
    const [cmsdata, setcmsdata] = useState('')
    const [faqcat, setfaqcat] = useState([])
    const [advertisement, setadvertisement] = useState('')

    var _loc = JSON.parse(localStorage.getItem("loc"))
    var user = JSON.parse(localStorage.getItem("user"))
 
    const GetFaqcategory = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`faq-category`)
            setloading(false)
            if(response?.status == 200){
                setfaqcat(response?.data?.data)
                GetFaq(response?.data?.data[0]?.id)
               
            } 
        } catch(err){
            setloading(false)
        }
    }

    const GetFaq = async (id)=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`faq?category=${id}`)
            setloading(false)
            if(response?.status == 200){
                setfaqList(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const GetTestimonial = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`testimonials`)
            setloading(false)
            if(response?.status == 200){
                settestimonialList(response?.data?.data?.section_1)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const GetVideo = async (type)=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`videos?${type}`)
            setloading(false)
            if(response?.status == 200){
                setvideoList(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const GetHomepagecontent = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`homepage-content`)
            setloading(false)
            if(response?.status == 200){
                sethomedata(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const Getsite = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`site-settings`)
            setloading(false)
            if(response?.status == 200){
                setsitedata(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const Getcms = async (slug)=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`cms?page_slug=${slug}`)
            setloading(false)
            if(response?.status == 200){
                setcmsdata(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    const Getadvertisement = async (slug)=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get(`add-management?page=${slug}&lat=${_loc?.latitude}&long=${_loc?.langtude}&user_id=${user?.id == null ? '': user?.id}`)
            setloading(false)
            if(response?.status == 200){
                setadvertisement(response?.data?.data)
            } 
        } catch(err){
            setloading(false)
        }
    }

    

  

    return {
        loading,
        faqList,
        GetFaq,
        GetTestimonial,
        testimonialList,
        GetVideo,
        videoList,
        GetHomepagecontent,
        homedata,
        Getsite,
        sitedata,
        Getcms,
        cmsdata,
        GetFaqcategory,
        faqcat,
        advertisement,
        Getadvertisement
    }
  
}



