import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection'
import Loader from '../../utils/Loader'
import { toast } from 'react-toastify'
import { AxiosRequest } from '../../utils/AxiosRequest'

const UpdateProfile = () => {
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [email, setemail] = useState("")
    const [phone, setphone] = useState("")
    const [loading, setloading] = useState(false)
    const {  Getadvertisement, advertisement} = AxiosRequest()
    var user = JSON.parse(localStorage.getItem("user"))


    const GetProfile = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get('get-profile')
            if(response?.status == 200){

                setloading(false)
               
                var editData = response.data?.data?.user

                setfname(editData?.first_name)
                setlname(editData?.last_name)
                setemail(editData?.email)
                setphone(editData?.phone)
            } else{
              
            }  
        } catch(err){
            setloading(false)
        }
    }

    const UpdateHandler = async () => {

        setloading(true)
        try{
            var FormData = require('form-data');
            var data = new FormData();
            data.append('first_name', fname);
            data.append('last_name', lname);
            data.append('email', email);
            data.append('phone', phone);
            const response = await ApiConnection.post(`update-profile`, data);
            if(response.status === 200){
                setloading(false)
                toast.success(response?.data?.message);
                GetProfile()
            } else {
                setloading(false)
            }

        } catch(err){
            setloading(false)
            if(err.response.status === 422){
                  toast.error(err.response?.data?.message);   
              
            }
    }
    

}

    useEffect(() => {
        GetProfile()
        Getadvertisement('update-profile-page')
    },[])

    const ClickHandle = async (link, id) =>{

        if(link !== ''){
          window.open(link)
        }
    
        var FormData = require('form-data');
        var data = new FormData();
        data.append("advertisement_id", id);
        data.append("page", 'update-profile-page');
        data.append("has_click", 1);
        data.append("device", 'web');
        data.append("user_id", user?.id == null ? '': user?.id);
        
        try {
            const  response = await ApiConnection.post(`add-management`, data)
           
            if(response?.data?.success){
               
       
           
            }  else {
              
              
              }
        } catch(err){
      
          
        
      }
    }

    return (
        <div className='afterlog-sec'>
                   {loading && <Loader/>}
        <div className='container-fluid'>
            <div className='row'>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Left'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
                <div className='col-lg-3'>
                    <LoginSidebar />
                </div>
                <div className='col-lg-5'>
                <div className='afterlog-sec-right'>
                <h2 className='headingStyle text-left'>Update Profile</h2>
            <div className='row'>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>First Name</label>
                <input type="text" className="form-control" placeholder="First Name"
                value={fname}
                onChange={(e)=>setfname(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Last Name</label>
                <input type="text" className="form-control" placeholder="Last Name"
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Email"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                readOnly
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Phone Number</label>
                <input type="text" className="form-control" placeholder="Phone Number"
                 value={phone}
                 onChange={(e)=>setphone(e.target.value)}
                 onKeyPress={(event) => {
                     if (!/[0-9]/.test(event.key)) {
                       event.preventDefault();
                     }
                   }}
                   maxLength="10"
                />
            </div>
            </div>
            {/* <div className='col-lg-12'>
            <div className='form-group'>
                <label>Profile Photo</label>
                <input type="file" className="form-control" 
           
                />
            </div>
            </div> */}
            <div className='col-lg-12 mt-2'>
     <div className='form-group text-right'>
                <button type='button' className='submitBtn' onClick={UpdateHandler}>Update</button>
            </div>
            </div>
            </div>
                </div>
            </div>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Right'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
          <div className='col-lg-12 mt-5'>
              {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
            </div>
        </div>
    </div>
      )
}

export default UpdateProfile