import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import ApiConnection from '../../utils/ApiConnection'
import Loader from '../../utils/Loader'

const Dashboard = () => {

    const [loading, setloading] = useState(false)
    const [dashboarddata, setdashboarddata] = useState('')

    const Getdata = async () =>{

        setloading(true)

        try {

            const response = await ApiConnection.get('dashboard')
            setloading(false)
            if(response?.status === 200) {
                setdashboarddata(response?.data?.data)
            }

        } catch (e){  setloading(false)}

    }

    useEffect(()=>{
        Getdata()
    },[])


  return (
    <div className='afterlog-sec'>
        {loading && <Loader/>}
    <div className='container'>
        <div className='row'>
            <div className='col-lg-3'>
                <LoginSidebar />
            </div>
            <div className='col-lg-9'>
            <div className='afterlog-sec-right'>
            <h2 className='headingStyle text-left'>Dashboard</h2>
        <div className='row'>
            <div className='col-lg-4 mb-3'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Total Rides                   
                    </h5>
                    <b>{dashboarddata?.total_trips}</b>
                </div>
            </div>
            <div className='col-lg-4 mb-3'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>New Rides                   
                    </h5>
                    <b>{dashboarddata?.new_trips}</b>
                </div>
            </div>
            <div className='col-lg-4 mb-3'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Upcoming Rides                   
                    </h5>
                    <b>{dashboarddata?.upcoming_trips}</b>
                </div>
            </div>
            <div className='col-lg-4 mb-3'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Completed Rides                   
                    </h5>
                    <b>{dashboarddata?.completed_trips}</b>
                </div>
            </div>
            <div className='col-lg-4 mb-3'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Rejected Rides                   
                    </h5>
                    <b>{dashboarddata?.rejected_trips}</b>
                </div>
            </div>
        </div>
            </div>
        </div>
        </div>
    </div>
</div>
  )
}

export default Dashboard